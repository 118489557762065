import React from 'react';
import './App.css';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';

const Home = React.lazy(() => import('./views/Home/home.js'));
const About = React.lazy(() => import('./views/AboutUs/about.js'));
const Products = React.lazy(() => import('./views/Products/products'));
const Contact = React.lazy(() => import('./views/ContactUs/contact.js'));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/product" element={<Products />} />
        <Route path="/" element={<Home />} />
        <Route path='*' element={<Navigate to={`/`} replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
