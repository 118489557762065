import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./fontawesome"
import 'font-awesome/css/font-awesome.min.css';
import SpinLoadDataComponent from './views/Common/Spin/SpinLoadDataComponent';

const loading = () => <div className="flex justify-center items-center h-screen"> <SpinLoadDataComponent /></div>

ReactDOM.render(
  <React.Suspense fallback={loading()}>
    <App />
  </React.Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
